<template>
    <PageDetails
        :project-id="projectId"
        :config-id="configId"
        :name="module && module.name"
        :config="config"
        :data="data"
        :target-type="target"
        :is-loading="isLoading"
        @save="onSave"
    />
</template>

<script>
import { get as _get } from "lodash";
import PageDetails from "@/components/model/projectmodule/PageDetails";

export default {
    components: {
        PageDetails,
    },

    data: function() {
        return {
            projectId: this.$route.params.projectId,
            configId: this.$route.params.configId,
            moduleId: this.$route.params.moduleId,

            module: null,

            config: null,
            data: null,
            target: null,

            isLoading: true,
        };
    },

    methods: {
        async fetchModule() {
            this.module = await this.$store.dispatch("admin/getProjectModule", {
                projectId: this.projectId,
                moduleId: this.moduleId,
            });
            const config = _get(this.module, "config") || {};
            const data = _get(this.module, "ProjectModule.data") || {};

            this.config = {
                ...(config.internal || {}),
                ...(data.config || {}),
            };
            this.data = data.data || {};
            this.target = data.target;
            this.isLoading = false;
        },

        async onSave(data) {
            this.isLoading = true;
            await this.$store.dispatch("admin/updateProjectModule", {
                projectId: this.projectId,
                moduleId: this.moduleId,
                data: data,
            });
            this.isLoading = false;
        },
    },

    async beforeMount() {
        await this.fetchModule();
    },
};
</script>
